<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false"></Nav>
    <CommonNav pageName="牧业产品"></CommonNav>
    <section>
      <img class="smart-farm-bg" src="../../assets/images/animal/bg.png" alt="">
    </section>
    <section class="smart-farm-container">
      <p class="smart-farm-head">与省农科院开展种养结合产学研合作，自主研发针对数字化养殖的农牧一体化解决方案。</p>
      <h4>种养结合数字化养殖解决方案</h4>
      <p class="smart-farm-article">以养殖产业数字化为目标，运用物联网、人工智能、云计算、区块链等数字科技技术，研发了数字环控系统、智能耳标系统和猪仔管家系统、养殖综合管理平台等核心产品，为规模化养殖企业转型升级、政府监管、养殖行业新业态新模式打造提供数字化养殖解决方案的企业。</p>
      <p class="smart-farm-article">基于物联网技术打造的全数字化环控系统，包括环境监测、环境控制和异常预警，可以接入灯光、饮水、料线等，成为数字化牧场中枢系统，已在省内多个规模化牧场应用。</p>
      <p class="smart-farm-article">数字牧场综合管理平台，作为牧场数字管理驾驶舱，可收集来自环境控制、生物安全、视频监控、生产管理数据等信息，为集中了解猪场情况提供综合信息。</p>
      <p class="smart-farm-article">智能耳标系统，为第三代耳标系统，通过边缘计算终端，自动远距离获取身份、耳根温度和运动频次信息，并送达养殖管理平台进行分析，完成猪只自动盘点、健康监测和数据化管理，服务规模化数字管理、政府监管、商业模式创新。</p>
      <p class="smart-farm-article">猪仔管家，通过视觉人工智能自动统计产房猪只数量，和智能耳标系统结合可以获取每头母猪年提供断奶仔猪数（PSY）、窝平产活仔数、窝平断奶仔猪数、年平产仔胎数和断奶日龄等，从而实现猪场核心数据的自动抓取。</p>
    </section>
    <section class="smart-farm-footer">
      <img src="../../assets/images/animal/imgFirst.png" alt="">
      <h6 class="smart-farm-footer-ctitle">养殖云平台界面</h6>
    </section>
    <section class="smart-farm-footer">
      <img src="../../assets/images/animal/imgSecond.png" alt="">
      <h6 class="smart-farm-footer-stitle">养猪计算器平台界面</h6>
    </section>
     <!-- 底部 -->
     <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import CommonNav from '@/components/commonNav.vue'
import Footer from '@/components/footer.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    Nav,
    CommonNav,
    Footer
  },
  beforeDestroy(){
    window.scrollTo(0,0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm{
  width: 100vw;
  padding-top: 120px;
}
.smart-farm-bg {
  width: 100%;
  height: 100%;
}
.smart-farm-container{
  width: 55%;
  max-width: 1057px;
  margin: 106px auto 126px;
  font-size: 22px;
  color: #808080;
  line-height: 2;
  text-align: left;
  // .smart-farm-head{
  //   margin-bottom: 20px;
  // }
  h4{
    height: 45px;
    font-size: 30px;
    color: #202020;
    font-weight: 500;
    padding-left: 10px;
    border-left: 3px solid #C7020A;
    margin-top: 30px;
    line-height: 45px;
  }
  .smart-farm-article{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
  .smart-farm-title{
    color: #C7050F;
    margin-bottom: 20px;
    font-weight: 500;
  }
  h5{
    font-weight: 400;
    font-size: 22px;
  }
}
.smart-farm-footer{
  width: 55%;
  max-width: 1057px;
  margin: 0 auto 107px;
  img{
   width: 94%;
  }
  h6{
    margin: 34px auto 0;
    font-weight: 400;
    font-size: 20px;
    color: #5B5B5B;
    position: relative;
  }
  h6::after{
    content:'';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #5B5B5B;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.smart-farm-footer-ctitle{
  width: 156px;
}
.smart-farm-footer-stitle{
  width: 200px;
}
</style>